export const FILE_MAX_BYTE_SIZE = 1024 * 1024 * 25; //25MB

type AcceptImportFileTypes = {
  [key: string]: string[];
};

export const ACCEPT_DATABASE_IMPORT_FILE_TYPES: AcceptImportFileTypes = {
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "application/pdf": [".pdf"],
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"],
  "image/gif": [".gif"],
  "image/webp": [".webp"],
};

export const ACCEPT_DOCUMENT_IMPORT_FILE_TYPES: AcceptImportFileTypes = {
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "application/pdf": [".pdf"],
};

export const ACCEPT_PUNCH_ICON_FILE_TYPES: AcceptImportFileTypes = {
  "image/png": [".png"],
  "image/jpeg": [".jpg", ".jpeg"],
  "image/svg+xml": [".svg"],
};

export const CHAT_IMAGE_FILE_TYPES = {
  "image/png": [".png"],
  "image/jpeg": [".jpg", ".jpeg"],
  "image/gif": [".gif"],
};

export const CHAT_VIDEO_FILE_TYPES = {
  "video/mp4": [".mp4"],
  "video/mpeg": [".mpeg"],
  "video/mov": [".mov"],
  "video/avi": [".avi"],
  "video/x-flv": [".flv"],
  "video/mpg": [".mpg"],
  "video/WebM": [".WebM"],
  "video/WMV": [".wmv"],
  "video/3gpp": [".3gpp"],
};

export const CHAT_DOCUMENT_FILE_TYPES = {
  "application/pdf": [".pdf"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
};

export const ACCEPT_CHAT_FILE_TYPES: AcceptImportFileTypes = {
  ...CHAT_IMAGE_FILE_TYPES,
  ...CHAT_VIDEO_FILE_TYPES,
  // ...CHAT_DOCUMENT_FILE_TYPES,
};
