import {
  CHAT_DOCUMENT_FILE_TYPES,
  CHAT_IMAGE_FILE_TYPES,
  CHAT_VIDEO_FILE_TYPES,
} from "@spesill/components/atoms/DropzoneFileField/constants";

import { getExtension } from "@spesill/utils";

import { Entity } from "./__common__/entity";
import { SourceType } from "./source";

export type ChatRoomMessageSenderType = "USER" | "AI";
export type FileType = "image" | "video" | "document" | "glb" | "unknown";

export const ACCEPT_IMAGE_EXTENSIONS = Object.values(
  CHAT_IMAGE_FILE_TYPES,
).flat();

export const ACCEPT_VIDEO_EXTENSIONS = Object.values(
  CHAT_VIDEO_FILE_TYPES,
).flat();

export const ACCEPT_TEXT_EXTENSIONS = Object.values(
  CHAT_DOCUMENT_FILE_TYPES,
).flat();

export class ChatRoomMessage extends Entity {
  id: string;
  message: string;
  sender: ChatRoomMessageSenderType;
  timestamp: Date;
  sources: SourceType[];

  constructor(arg: ExcludeMethods<ChatRoomMessage>) {
    super(arg);

    this.id = arg.id;
    this.message = arg.message;
    this.sender = arg.sender;
    this.sources = arg.sources;
    this.timestamp = arg.timestamp;
  }

  static getFileType(storagePath: string): FileType {
    // パスから拡張子を取得（小文字に変換して処理）
    const extension = getExtension(storagePath, true);

    if (!extension) {
      return "unknown";
    }

    if (ACCEPT_VIDEO_EXTENSIONS.includes(extension)) {
      return "video";
    }
    if (ACCEPT_IMAGE_EXTENSIONS.includes(extension)) {
      return "image";
    }
    if (ACCEPT_TEXT_EXTENSIONS.includes(extension)) {
      return "document";
    }

    // GLBファイル
    if (extension === "glb") {
      return "glb";
    }

    return "unknown";
  }
}
